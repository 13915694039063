import React from "react";

export const NotificationContent = ({ rayId }) => (
  <p>
    Cloudflare has detected a security issue. To report an issue with the
    EthicsLine, please contact the{" "}
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://support.epam.com/ess?id=sc_cat_item&sys_id=9dc111c147bf1110ed13b2bd436d43b9&name=ReportAnIssue&sysparm_copy_vars=%7B%22u_warning%22%3A%22%22%2C%22u_confrmation%22%3A%22false%22%2C%22u_topic%22%3A%2246128e86971c2150386e3a871153af4d%22%7D"
    >
      EPAM IT Support
    </a>{" "}
    team.
    <br />
    <br />
    Cloudflare Ray ID (for EPAM IT Support request): {rayId}
  </p>
);
