/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import React, { Component } from "react";
import { defineMessages, FormattedHTMLMessage, injectIntl } from "react-intl";

import LanguagePicker from "../../../business/language-picker/language-picker";

import "./header.scss";

defineMessages({
  headerTitle: {
    id: "header.title",
    defaultMessage: "<span class='notranslate'>EPAM EthicsLine</span>",
  },
});

@injectIntl
class Header extends Component {
  render() {
    return (
      <div>
        <div className="header">
          <div className="logo">
            <div className="logo__picture">
              <div className="logo__picture-background" />
            </div>
            <div className="title">
              <FormattedHTMLMessage id="header.title" />
            </div>
          </div>
          <LanguagePicker />
        </div>
      </div>
    );
  }
}

export default Header;
