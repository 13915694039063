/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import omit from "lodash/omit";

import { otherItem } from "../features/submission/utils";
import { formatDate, formData } from "../utils/utils";
import {
  AUTH,
  CHECK_RESET_TOKEN,
  DOWNLOAD_FILE_ENDPOINT,
  GET_CAPTCHA,
  REPORTS_USERS,
  RESET_PASSWORD,
  SEND_RESET_NOTIFICATION,
  SET_PASSWORD,
  SUBMIT_REPORT_ENDPOINT,
  USER_MESSAGE_ENDPOINT,
} from "./endpoints";

export const SUBMIT_REPORT_REQUEST = "SUBMIT_REPORT_REQUEST";
export const SUBMIT_REPORT_SUCCESS = "SUBMIT_REPORT_SUCCESS";
export const SUBMIT_REPORT_FAILURE = "SUBMIT_REPORT_FAILURE";

export const PASSWORD_RECOVERY_REQUEST = "PASSWORD_RECOVERY_REQUEST";
export const PASSWORD_RECOVERY_SUCCESS = "PASSWORD_RECOVERY_SUCCESS";
export const PASSWORD_RECOVERY_FAILURE = "PASSWORD_RECOVERY_FAILURE";
export const PASSWORD_RECOVERY_RESET = "PASSWORD_RECOVERY_RESET";

export const SEND_NOTIFICATION_REQUEST = "SEND_NOTIFICATION_REQUEST";
export const SEND_NOTIFICATION_SUCCESS = "SEND_NOTIFICATION_SUCCESS";
export const SEND_NOTIFICATION_FAILURE = "SEND_NOTIFICATION_FAILURE";
export const SEND_NOTIFICATION_RESET = "SEND_NOTIFICATION_RESET";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const CHECK_RESET_TOKEN_REQUEST = "CHECK_RESET_TOKEN_REQUEST";
export const CHECK_RESET_TOKEN_SUCCESS = "CHECK_RESET_TOKEN_SUCCESS";
export const CHECK_RESET_TOKEN_FAILURE = "CHECK_RESET_TOKEN_FAILURE";

export const GET_CAPTCHA_REQUEST = "GET_CAPTCHA_REQUEST";
export const GET_CAPTCHA_SUCCESS = "GET_CAPTCHA_SUCCESS";
export const GET_CAPTCHA_FAILURE = "GET_CAPTCHA_FAILURE";

export const ISSUE_SUMMARY_REQUEST = "ISSUE_SUMMARY_REQUEST";
export const ISSUE_SUMMARY_SUCCESS = "ISSUE_SUMMARY_SUCCESS";
export const ISSUE_SUMMARY_FAILURE = "ISSUE_SUMMARY_FAILURE";

export const SUBMIT_USER_MESSAGE = "SUBMIT_USER_MESSAGE_REQUEST";
export const SUBMIT_USER_MESSAGE_SUCCESS = "SUBMIT_USER_MESSAGE_SUCCESS";
export const SUBMIT_USER_MESSAGE_FAILURE = "SUBMIT_USER_MESSAGE_FAILURE";

export const FILE_DOWNLOAD_REQUEST = "FILE_DOWNLOAD_REQUEST";
export const FILE_DOWNLOAD_SUCCESS = "FILE_DOWNLOAD_SUCCESS";
export const FILE_DOWNLOAD_FAILURE = "FILE_DOWNLOAD_FAILURE";

const convertFieldToString = (dataField) => {
  if (!dataField) return "";

  const { country, city, comment } = dataField;

  return [...[country, city].filter((i) => i !== otherItem.name), comment]
    .filter(Boolean)
    .join(", ");
};

export function submitReport(entry) {
  const { date, time, anonymous, specificDate, anonymousEmail } = entry;
  let data = omit(entry, [
    "anonymous",
    "date",
    "time",
    "specificDate",
    "anonymousEmail",
  ]);

  if (anonymous) {
    data = omit(data, "location", "name", "phone", "employeeType", "email");
    if (anonymousEmail) {
      data.email = anonymousEmail;
    }
  }

  if (specificDate) {
    const formatTimeValue = (value) => `${value < 10 ? "0" : ""}${value}`;

    const dateString = formatDate(date);
    const timeString = time
      ? ` ${formatTimeValue(time.hours)}:${formatTimeValue(time.minutes)}:01`
      : "";

    data.incidentDate = dateString + timeString;

    data = omit(data, "timeFrame");
  }

  // BECAUSE OF REACT-DROPDOWN
  data.incidentLocation = convertFieldToString(data.incidentLocation);
  data.location = convertFieldToString(data.location);

  const body = formData(data);

  return {
    type: SUBMIT_REPORT_REQUEST,
    payload: {
      url: SUBMIT_REPORT_ENDPOINT,
      options: {
        method: "POST",
        body,
      },
    },
  };
}

export function passwordReset({
  password,
  confirmPassword,
  isSetPassword,
  token,
  ...anotherValues
}) {
  return {
    type: PASSWORD_RECOVERY_REQUEST,
    payload: {
      url: `${isSetPassword ? SET_PASSWORD : RESET_PASSWORD}?token=${token}`,
      options: {
        method: "POST",
        body: {
          password,
          confirmPassword,
          ...anotherValues,
        },
      },
    },
  };
}

export function passwordResetPopup({ recoveryLogin: login, ...anotherData }) {
  return {
    type: SEND_NOTIFICATION_REQUEST,
    payload: {
      url: SEND_RESET_NOTIFICATION,
      options: {
        method: "POST",
        body: {
          login,
          ...anotherData,
        },
      },
    },
  };
}

export function checkResetToken({ token }) {
  return {
    type: CHECK_RESET_TOKEN_REQUEST,
    payload: {
      url: `${CHECK_RESET_TOKEN}?token=${token}`,
      options: {
        method: "GET",
      },
    },
  };
}

export function getCaptcha() {
  return {
    type: GET_CAPTCHA_REQUEST,
    payload: {
      url: `${GET_CAPTCHA}`,
      options: {
        method: "GET",
      },
    },
  };
}

export function login(
  { login, password, ...anotherData },
  history,
  isAdmin = false
) {
  return {
    type: LOGIN,
    meta: {
      history,
      isAdmin,
    },
    payload: {
      url: AUTH,
      options: {
        method: "POST",
        body: {
          login,
          password,
          ...anotherData,
        },
      },
    },
  };
}

export function issueSummary() {
  return {
    type: ISSUE_SUMMARY_REQUEST,
    payload: {
      url: REPORTS_USERS,
    },
  };
}

export function submitUserMessage(values) {
  const body = formData(values);
  const { UserId } = values;
  return {
    type: SUBMIT_USER_MESSAGE,
    meta: {
      UserId,
    },
    payload: {
      url: USER_MESSAGE_ENDPOINT,
      options: {
        method: "POST",
        body,
      },
    },
  };
}

export function downloadFile(file) {
  return {
    type: FILE_DOWNLOAD_REQUEST,
    payload: {
      url: `${DOWNLOAD_FILE_ENDPOINT}/${file.id}`,
      options: {
        responseBlob: true,
        file,
      },
    },
  };
}
