/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { autobind } from "core-decorators";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { defineMessages } from "react-intl";
import { connect } from "react-redux";
import { Redirect, Switch } from "react-router-dom";
import { bindActionCreators } from "redux";

import { ApmRoute } from "@elastic/apm-rum-react";

import asyncComponent from "../../business/async-component/async-component";
import Notifications from "../../business/notifications/notifications";
import Footer from "../../components/general/footer/footer";
import Header from "../../components/general/header/header";
import { Loader } from "../../components/general/loader/loader";
import { ServerError } from "../server-error/server-error";
import { publicPath } from "./constants";
import { routes } from "./routes";

import "./app.scss";

const AsyncHome = asyncComponent(() => import("../home/home"));
const AsyncSubmission = asyncComponent(
  () => import("../submission/submission")
);
const AsyncIssueSummary = asyncComponent(
  () => import("../issue-summary/issue-summary")
);
const AsyncPasswordRecovery = asyncComponent(
  () => import("../password-recovery/password-recovery")
);
const AsyncSysVersion = asyncComponent(
  () => import("../sys-version/sys-version")
);

defineMessages({
  appRequiredFieldError: {
    id: "app.errors.required-field",
    defaultMessage: "This field is required to fill",
  },
  appInvalidEmailError: {
    id: "app.errors.invalid-email",
    defaultMessage: "Invalid email address",
  },
  appInvalidPhoneNumber: {
    id: "app.errors.invalid-phone-number",
    defaultMessage: "Invalid phone number",
  },
  appWeakPassword: {
    id: "app.errors.weak-password",
    defaultMessage: "Your password does not meet all requirements",
  },
  appWrongPasswordConfirm: {
    id: "app.errors.wrong-password-confirm",
    defaultMessage: "Passwords are different",
  },
  appMaxLengthExceeded: {
    id: "app.errors.max-length",
    defaultMessage: "Maximum limit of characters is {number}",
  },
  appEmptyFilesError: {
    id: "app.errors.empty-files",
    defaultMessage:
      "Document(s) {name} could not be uploaded as it doesn't contain any information.",
  },
  appTooManyFilesError: {
    id: "app.errors.too-many-files",
    defaultMessage:
      "The files are being attached exceed file amount. Files amount should be no more than 5 files.",
  },
  appTooBigFile: {
    id: "app.errors.too-big-file",
    defaultMessage:
      "Document(s) {name} could not be uploaded as it exceeds valid size. Valid size is {maxSize}mb.",
  },
});

@connect(
  (state) => ({
    isLoading: state.app.isLoading,
    serverError: state.app.serverError,
  }),
  (dispatch) => bindActionCreators({}, dispatch)
)
class App extends Component {
  static propTypes = {
    isLoading: PropTypes.bool,
  };

  async componentDidMount() {
    // WORKAROUND BECAUSE OF UI KIT BUG
    // TODO REMOVE IT WHEN IT WILL BECOME FIXED

    const svgSprite = document.getElementById("__SVG_SPRITE_NODE__");
    if (svgSprite && svgSprite.style) {
      svgSprite.style.width = 0;
      svgSprite.style.height = 0;
      svgSprite.style.position = "absolute";
    }
  }

  @autobind
  renderLayout(WrappedComponent, { ...customProps }) {
    const AppLayoutWrapper = (props) => {
      return (
        <div className="app__view">
          <WrappedComponent {...props} {...customProps} />
          <Notifications />
        </div>
      );
    };
    AppLayoutWrapper.displayName = "AppLayoutWrapper";
    return AppLayoutWrapper;
  }

  render() {
    const { isLoading, serverError } = this.props;

    return (
      <div className="app">
        <div className="app__header">
          <Header />
        </div>
        {serverError ? (
          <ServerError />
        ) : !isLoading ? (
          <Switch>
            <Redirect exact from={publicPath} to={routes.HOME.path} />
            <ApmRoute
              path={routes.HOME.path}
              render={this.renderLayout(AsyncHome, {
                pageName: routes.HOME.name,
              })}
            />
            <ApmRoute
              path={routes.SUBMIT_CONCERN.path}
              render={this.renderLayout(AsyncSubmission, {
                pageName: routes.SUBMIT_CONCERN.name,
                isConcern: true,
              })}
            />
            <ApmRoute
              path={routes.SUBMIT_QUESTION.path}
              render={this.renderLayout(AsyncSubmission, {
                pageName: routes.SUBMIT_QUESTION.name,
                isConcern: false,
              })}
            />
            <ApmRoute
              path={routes.SUBMIT_REPORT_SUCCESS.path}
              render={this.renderLayout(AsyncSubmission, {
                pageName: routes.SUBMIT_REPORT_SUCCESS.name,
              })}
            />
            <ApmRoute
              path={`${routes.ISSUE_SUMMARY.path}/:id`}
              render={this.renderLayout(AsyncIssueSummary, {
                pageName: routes.ISSUE_SUMMARY.name,
              })}
            />
            <ApmRoute
              path={routes.ISSUE_SUMMARY.path}
              render={this.renderLayout(AsyncIssueSummary, {
                pageName: routes.ISSUE_SUMMARY.name,
              })}
            />
            <ApmRoute
              path={routes.RECOVERY_PASSWORD.path}
              render={this.renderLayout(AsyncPasswordRecovery, {
                pageName: routes.RECOVERY_PASSWORD.name,
              })}
            />
            <ApmRoute
              path={routes.SET_PASSWORD.path}
              render={this.renderLayout(AsyncPasswordRecovery, {
                pageName: routes.SET_PASSWORD.name,
                isSetPassword: true,
              })}
            />
            <ApmRoute
              path={routes.SYS_VERSION.path}
              render={this.renderLayout(AsyncSysVersion, {
                pageName: routes.SYS_VERSION.name,
              })}
            />
            <Redirect exact from={"*"} to={routes.HOME.path} />
          </Switch>
        ) : (
          <div className="app__loading">
            <Loader />
          </div>
        )}
        <div className="app__footer">
          <Footer />
        </div>
      </div>
    );
  }
}

export default App;
