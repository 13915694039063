/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import get from "lodash/get";
import {
  actionChannel,
  call,
  cancel,
  fork,
  put,
  select,
  take,
  takeEvery,
} from "redux-saga/effects";

import {
  CHECK_RESET_TOKEN_REQUEST,
  FILE_DOWNLOAD_REQUEST,
  GET_CAPTCHA_REQUEST,
  ISSUE_SUMMARY_REQUEST,
  LOGIN,
  PASSWORD_RECOVERY_REQUEST,
  SEND_NOTIFICATION_REQUEST,
  SUBMIT_REPORT_REQUEST,
  SUBMIT_USER_MESSAGE,
} from "../../../api/actions";
import { fetchData } from "../../../api/fetch-wrapper";
import { showNotification } from "../../../business/notifications/actions";
import { ErrorNotification } from "../../../business/notifications/utils";
import { SESSION_DURATION } from "../../../utils/constants";
import { addMinutesToCurrentTime } from "../../../utils/utils";
import { doLogout, LOGOUT } from "../actions/app";

function getActionBaseName(type) {
  const splitted = type.split("_");
  return splitted.length > 1 ? splitted.slice(0, -1).join("_") : type;
}

export function* handleRequest(action, runningTasks) {
  const {
    payload: { url },
    type,
    meta,
  } = action;

  try {
    const {
      app: {
        auth: { access_token: accessToken },
      },
      intl: { locale },
    } = yield select();
    const actionBaseName = getActionBaseName(type);

    // you can add action-specific data here
    const additionalData = {
      ...(actionBaseName === LOGIN
        ? {
            sessionTimeout: addMinutesToCurrentTime(SESSION_DURATION).getTime(),
          }
        : {}),
    };

    const { response, error, options } = yield call(
      fetchData,
      { url, locale, accessToken },
      action.payload.options
    );

    if (error) {
      if (error.status === 401) {
        yield put(doLogout(error));
      }

      if (error.status === 403) {
        const notification = new ErrorNotification(error.message, 20);

        yield put(showNotification({ notification }));
      }

      yield put({
        type: `${actionBaseName}_FAILURE`,
        error,
        options,
        payload: response,
        meta,
      });
    } else {
      yield put({
        type: `${actionBaseName}_SUCCESS`,
        payload:
          response instanceof Blob || Array.isArray(response)
            ? response
            : { ...response, ...additionalData },
        options,
        meta,
      });
    }
  } finally {
    if (runningTasks && runningTasks[type]) {
      runningTasks[type] = undefined;
    }
  }
}

function* watchRequests() {
  const runningTasks = {}; // Buffer for all the requests
  const requestChannel = yield actionChannel([
    // Actions to be handled (e.g. SUMMARY_REQUEST)
    SUBMIT_REPORT_REQUEST,
    LOGIN,
    ISSUE_SUMMARY_REQUEST,
    SUBMIT_USER_MESSAGE,
    FILE_DOWNLOAD_REQUEST,
    PASSWORD_RECOVERY_REQUEST,
    CHECK_RESET_TOKEN_REQUEST,
    GET_CAPTCHA_REQUEST,
    SEND_NOTIFICATION_REQUEST,
  ]);

  const nonCancellable = {
    // Put here actions that shouldn't be cancelled
    FILE_DOWNLOAD_REQUEST,
  };

  while (true) {
    const action = yield take(requestChannel);
    const prevTask = runningTasks[action.type];

    if (prevTask) {
      const actionBaseName = getActionBaseName(action.type);

      if (!nonCancellable[action.type]) {
        yield cancel(prevTask);
        yield put({
          type: `${actionBaseName}CANCEL`,
          options: prevTask.options,
        });
      }
    }

    runningTasks[action.type] = yield fork(handleRequest, action, runningTasks);
    runningTasks[action.type].options = get(action, "payload.options");
  }
}

function logout() {
  sessionStorage.removeItem("EL.AUTH");
  sessionStorage.removeItem("EL.SESSION_TIMEOUT");
}

function* watchLogout() {
  yield takeEvery(LOGOUT, logout);
}

export function* sagasGeneral() {
  yield fork(watchLogout);
  yield fork(watchRequests);
}
