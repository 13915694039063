/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import produce from "immer";
import get from "lodash/get";

import {
  SEND_NOTIFICATION_FAILURE,
  SEND_NOTIFICATION_REQUEST,
  SEND_NOTIFICATION_RESET,
  SEND_NOTIFICATION_SUCCESS,
} from "../../api/actions";
import { processCustomError } from "../../utils/utils";

export const passwordRecoveryPopupSelector = (state) =>
  state.passwordRecoveryPopup;

const initialState = {
  success: false,
  submitting: false,
  errors: {},
};

// export default function reducer(state = initialState, action = {}) {
export default produce((draft, action = {}) => {
  switch (action.type) {
    case SEND_NOTIFICATION_REQUEST:
      draft.submitting = true;
      break;
    case SEND_NOTIFICATION_SUCCESS:
      draft.success = true;
      draft.submitting = false;
      break;
    case SEND_NOTIFICATION_FAILURE:
      draft.submitting = false;
      draft.success = false;
      draft.errors = get(action, "payload.errors") ||
        processCustomError(action.payload) || {
          error: "Unknown error",
        };
      draft.errorStatus = action.error.status;
      draft.isBlocked = action.error.isBlocked;
      draft.message = action.error.message;
      break;
    case SEND_NOTIFICATION_RESET:
      Object.assign(draft, initialState);
      break;
  }
}, initialState);
