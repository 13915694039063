/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import values from "lodash/values";
import React, { Component } from "react";
import Dropdown from "react-dropdown";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { setLocale } from "../../../i18n/actions";
import { LANGUAGES } from "../../../i18n/languages";

import "react-dropdown/style.css";
import "./language-picker.scss";

const mapStateToProps = (state) => ({
  lng: state.intl.locale,
});

const browsers = {
  EDGE: {
    name: "edge",
    link: "https://microsoftedge.microsoft.com/addons/detail/google-translate-in-right/fcoongackakfdmiincikmjgkedcgjkdp?hl=en-US&gl=E",
  },
  FIREFOX: {
    name: "firefox",
    link: "https://addons.mozilla.org/en-US/firefox/addon/to-google-translate/?utm_source=addons.mozilla.org&utm_medium=referral&utm_content=search",
  },
  CHROME: {
    name: "chrome",
    link: "https://chrome.google.com/webstore/detail/google-translate/aapbdbdomjkkjkaonfhkkikfgjllcleb?hl=en-US",
  },
  OTHER: {
    name: "other",
    link: null,
  },
};

const defineBrowser = () => {
  const userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/edg/i)) {
    browserName = browsers.EDGE.name;
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = browsers.FIREFOX.name;
  } else if (userAgent.match(/opr\//i)) {
    browserName = browsers.OTHER.name;
  } else if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = browsers.CHROME.name;
  } else {
    browserName = browsers.OTHER.name;
  }

  return browserName;
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setLocale,
    },
    dispatch
  );

@connect(mapStateToProps, mapDispatchToProps)
class LanguagePickerContainer extends Component {
  constructor(props) {
    super(props);
    this._url = "";
    const browser = defineBrowser();
    let translatorHidden = false;
    switch (browser) {
      case browsers.CHROME.name:
        this._url = browsers.CHROME.link;
        translatorHidden = true;
        break;
      case browsers.EDGE.name:
        this._url = browsers.EDGE.link;
        translatorHidden = true;
        break;
      case browsers.FIREFOX.name:
        this._url = browsers.FIREFOX.link;
        translatorHidden = true;
        break;
      default:
        this._url = null;
        translatorHidden = false;
        break;
    }

    const registered = String.fromCodePoint(0x000ae);

    if (translatorHidden) {
      this.langOptions = {
        ...LANGUAGES,
        google: {
          locale: "google",
          name: `Translate the page with Google Translate${registered}`,
          shortCode: "gG",
        },
      };
    } else {
      this.langOptions = {
        ...LANGUAGES,
      };
    }
  }

  render() {
    const { lng, setLocale } = this.props;

    const languagesList = values(this.langOptions).map((v) => ({
      value: v.locale,
      label: v.name,
    }));

    return (
      <div className="language-picker">
        <div
          className={`language-picker__flag language-picker__flag--${LANGUAGES[lng].shortCode}`}
        />
        <Dropdown
          options={languagesList}
          value={lng}
          className={`language-picker__select language-picker__select--${LANGUAGES[lng].shortCode}`}
          onChange={({ value }) => {
            if (value === "google") {
              window.open(this._url);
              this.setState({ lng });
            } else {
              setLocale(value);
            }
          }}
        />
      </div>
    );
  }
}

export default LanguagePickerContainer;
