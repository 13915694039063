/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

export const APP_SERVER_ERROR = "APP_SERVER_ERROR";

export const LOGOUT = "LOGOUT";

export function serverErrorAction() {
  return {
    type: APP_SERVER_ERROR,
  };
}

export function doLogout() {
  return {
    type: LOGOUT,
  };
}
