/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { change, formValueSelector, reset } from "redux-form";
import { fork, put, select, takeEvery } from "redux-saga/effects";

import { SUBMIT_USER_MESSAGE_SUCCESS } from "../../api/actions";
import {
  COMMUNICATION_FILE_UPLOAD_REMOVE,
  COMMUNICATION_FILE_UPLOAD_SUCCESS,
} from "./actions";

// communication: set attachments
function* setAttachments(action) {
  const oldFiles = yield select((state) =>
    formValueSelector("communication")(state, "files")
  );
  const { files: newFiles } = action.payload;
  yield put(change("communication", "files", oldFiles.concat(newFiles)));
}

function* watchSetAttachments() {
  yield takeEvery(COMMUNICATION_FILE_UPLOAD_SUCCESS, setAttachments);
}

// communication: remove attachment
function* removeAttachment(action) {
  const files = yield select((state) =>
    formValueSelector("communication")(state, "files")
  );
  yield put(
    change(
      "communication",
      "files",
      files.filter((file, index) => index !== action.payload)
    )
  );
}

function* watchRemoveAttachment() {
  yield takeEvery(COMMUNICATION_FILE_UPLOAD_REMOVE, removeAttachment);
}

// submit user message
function* submitUserMessage() {
  yield put(reset("communication"));
}

function* watchSubmitUserMessage() {
  yield takeEvery(SUBMIT_USER_MESSAGE_SUCCESS, submitUserMessage);
}

export function* sagasCommunication() {
  yield fork(watchSetAttachments);
  yield fork(watchRemoveAttachment);
  yield fork(watchSubmitUserMessage);
}
