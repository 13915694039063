/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import React, { Component } from "react";
import { defineMessages, injectIntl } from "react-intl";

import PhoneNumber from "../../../business/phone-number/phone-number";
import { LOCALE } from "./constants";

import "./contact-us.scss";

defineMessages({
  contactUsTitle: {
    id: "contact-us.title",
    defaultMessage: "Contact us",
  },
  contactUsMessage: {
    id: "contact-us.message",
    defaultMessage:
      "If you would prefer to speak to someone confidentially,\ncall us and one of our representatives would be happy to assist you.",
  },
  contactUsPhoneNumberUs: {
    id: "contact-us.phone.us",
    defaultMessage: "+1 866 7869137",
  },
  contactUsPhoneNumberRu: {
    id: "contact-us.phone.ru",
    defaultMessage: "+375 17 3890191",
  },
});

@injectIntl
class ContactUs extends Component {
  render() {
    const { intl } = this.props;
    return (
      <div className="contact-us">
        <div className="title">
          <div className="title__text">
            {intl.formatMessage({ id: "contact-us.title" })}
          </div>
          <div className="title__underline" />
        </div>
        <div className="contact-us__message">
          {intl.formatMessage({ id: "contact-us.message" })}
        </div>
        <div className="contact-us__numbers">
          <PhoneNumber
            locale={LOCALE.us}
            value={intl.formatMessage({ id: "contact-us.phone.us" })}
          />
          {/* <PhoneNumber
            locale={LOCALE.ru}
            value={intl.formatMessage({ id: "contact-us.phone.ru" })}
          /> */}
        </div>
      </div>
    );
  }
}

export default ContactUs;
