/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { fork, put, takeEvery } from "redux-saga/effects";

import {
  FILE_DOWNLOAD_FAILURE,
  FILE_DOWNLOAD_SUCCESS,
} from "../../../api/actions";
import {
  communicationFileUploadRemove,
  communicationFileUploadSuccess,
} from "../../../business/communication/actions";
import { COMMUNICATION_SECTION } from "../../../business/communication/constants";
import {
  issueSummaryFileUploadRemove,
  issueSummaryFileUploadSuccess,
} from "../../../features/issue-summary/actions";
import { ISSUE_SUMMARY_SECTION } from "../../../features/issue-summary/constants";
import {
  submissionFileUploadRemove,
  submissionFileUploadSuccess,
} from "../../../features/submission/actions";
import { SUBMISSION_SECTION } from "../../../features/submission/constants";
import {
  FILE_UPLOAD_REMOVE,
  FILE_UPLOAD_START,
  fileDownladFailed,
  fileDownladSucceded,
  fileUploadSuccess,
} from "./actions";

// start file upload
function* fileUploadStart(action) {
  const { files, section } = action.payload;

  switch (section) {
    case SUBMISSION_SECTION:
      yield put(submissionFileUploadSuccess({ files }));
      break;

    case ISSUE_SUMMARY_SECTION:
      yield put(issueSummaryFileUploadSuccess({ files }));
      break;

    case COMMUNICATION_SECTION:
      yield put(communicationFileUploadSuccess({ files }));
      break;

    default:
      break;
  }

  yield put(fileUploadSuccess());
}

function* watchFileUploadStart() {
  yield takeEvery(FILE_UPLOAD_START, fileUploadStart);
}

// remove file upload
function* fileUploadRemove(action) {
  const { index, section } = action.payload;

  switch (section) {
    case SUBMISSION_SECTION:
      yield put(submissionFileUploadRemove(index));
      break;

    case ISSUE_SUMMARY_SECTION:
      yield put(issueSummaryFileUploadRemove(index));
      break;

    case COMMUNICATION_SECTION:
      yield put(communicationFileUploadRemove(index));
      break;

    default:
      break;
  }
}

function* watchFileUploadRemove() {
  yield takeEvery(FILE_UPLOAD_REMOVE, fileUploadRemove);
}

// file download success
function* fileDownloadSuccess(action) {
  const {
    payload: blob,
    options: {
      file: { name, id },
    },
  } = action;
  const a = document.createElement("a");
  const url = URL.createObjectURL(blob);
  a.href = url;
  a.download = name;
  a.click();
  yield put(fileDownladSucceded(id));
  setTimeout(() => URL.revokeObjectURL(url), 100);
}

function* watchFileDownloadSuccess() {
  yield takeEvery(FILE_DOWNLOAD_SUCCESS, fileDownloadSuccess);
}

// file download failure
function* fileDownloadFailure(action) {
  const {
    options: {
      file: { id },
    },
  } = action;
  yield put(fileDownladFailed(id));
}

function* watchFileDownloadFailure() {
  yield takeEvery(FILE_DOWNLOAD_FAILURE, fileDownloadFailure);
}

export function* sagasFileUpload() {
  yield fork(watchFileUploadStart);
  yield fork(watchFileUploadRemove);
  yield fork(watchFileDownloadSuccess);
  yield fork(watchFileDownloadFailure);
}
