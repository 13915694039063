/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import React from "react";

import { LinkButton } from "@epam/loveship";

import "./notification.scss";

const Notification = ({ content, type, onClose, actions = [], style }) => {
  return (
    <div
      className={`notification ${type ? `notification--${type}` : ""}`}
      style={style}
    >
      <div className="notification__upper-colored" />
      <div className="notification__inner">
        <div className="notification__icon-container">
          <div className="notification__icon">!</div>
        </div>
        <div className="notification__content">
          {typeof content === "string" ? (
            <p className="notification__text">{content}</p>
          ) : (
            <span className="notification__text">{content}</span>
          )}

          <div className="notification__actions">
            {actions.map(({ action, id, label }) => {
              return (
                <LinkButton
                  className="notification__action"
                  caption={label.toUpperCase()}
                  font="sans-semibold"
                  fontSize={14}
                  size={24}
                  key={id}
                  onClick={() => {
                    action();
                    onClose();
                  }}
                />
              );
            })}
          </div>
        </div>
        <button
          type="button"
          onClick={onClose}
          className="notification__close"
        />
      </div>
    </div>
  );
};

export default Notification;
