/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

export const SUBMISSION_SECTION = "submission";

export const MAX_LENGTHS = {
  subject: 200,
  details: 25000,
  subjectPeopleNames: 100,
  subjectPeopleDetails: 200,
  involvedPeopleNames: 100,
  involvedPeopleDetails: 200,
  involvedPeopleComments: 500,
  name: 100,
  email: 50,
  phone: 50,
  "location.comment": 170,
  "incidentLocation.comment": 170,
  timeFrame: 100,
  Text: 7500,
};

export const CUSTOM_ERRORS_CODE = {
  XSS_DETECTED: 5001,
  ADDRESS_ACCESS: 5002,
};
