/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import fetchPonyfill from "fetch-ponyfill";
import isArray from "lodash/isArray";
import React from "react";

import { store } from "../..";
import { serverErrorAction } from "../features/app/actions/app";
import { NotificationContent } from "./utils/notification-content";

const { fetch } = fetchPonyfill();

function getCloudflareErrorText(htmlText) {
  const rayIdMatch = htmlText.match(/ray id: ?(<\/?[^>]+(>|$))?[a-z0-9-]*/gi);

  const rayId =
    rayIdMatch && rayIdMatch[0].replace(/ray id: (<\/?[^>]+(>|$))?/i, "");

  if (rayId) {
    return <NotificationContent rayId={rayId} />;
  } else {
    return "Something went wrong!";
  }
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 400) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;

  throw error;
}

function parseJSON(response) {
  if (response._bodyBlob && response._bodyBlob.size === 0) {
    return Promise.resolve({});
  }

  return response.json();
}

function getQueryString(url, data) {
  if (!data) {
    return "";
  }

  const esc = encodeURIComponent;

  const query = Object.keys(data)
    .map((k) => {
      if (k && isArray(data[k]) && data[k].length > 0) {
        return data[k].map((id) => `${esc(k)}=${esc(id)}`).join("&");
      }

      return `${esc(k)}=${esc(data[k])}`;
    })
    .join("&");

  const querySymbol = url.indexOf("?") >= 0 ? "&" : "?";

  return query ? `${querySymbol}${query}` : "";
}

export function fetchData({ url, locale, accessToken }, fetchOptions) {
  const options = fetchOptions || {};
  const method = options.method || "GET";
  let body;
  let headers = options.headers || {};

  const fetchUrl = `${url}${getQueryString(url, options.data)}`;

  if (options.body instanceof FormData) {
    body = options.body;
  } else if (["POST", "PUT", "DELETE"].indexOf(method) > -1) {
    headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    body = JSON.stringify(options.body);
  }

  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }

  if (locale) {
    headers["Accept-Language"] = `${locale}`;
  }

  return fetch(fetchUrl, { method, headers, body })
    .then(checkStatus)
    .then(options.responseBlob ? (response) => response.blob() : parseJSON)
    .then((response) => ({ response, options }))
    .catch((error) => {
      const status = error.response ? error.response.status : null;
      const message = error.message
        ? `${status} ${error.message}`
        : error.response
          ? error.response.message
          : null;

      if (status === 400 || status === 403 || status === 404) {
        if (
          error.response._bodyBlob &&
          error.response._bodyBlob.size !== 0 &&
          error.response._bodyBlob.type === "text/html"
        ) {
          return error.response.text().then((htmlText) => {
            const title = getCloudflareErrorText(htmlText);
            return {
              error: {
                message: title,
                status,
              },
            };
          });
        }

        return parseJSON(error.response).then((data) => ({
          response: data,
          options,
          error: {
            message: data.Message || data.message || message,
            status,
          },
        }));
      } else if (status === 410) {
        return parseJSON(error.response).then((data) => {
          return {
            response: data,
            options,
            error: {
              message: data.Message || data.message || message,
              status,
              isBlocked: data.isBlocked,
            },
          };
        });
      } else if (status === 500) {
        return store.dispatch(serverErrorAction());
      }

      return {
        error: {
          message,
          status,
        },
        options,
      };
    })
    .catch((error) => ({ error }));
}
