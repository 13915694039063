/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import produce from "immer";
import get from "lodash/get";

import {
  CHECK_RESET_TOKEN_FAILURE,
  CHECK_RESET_TOKEN_REQUEST,
  CHECK_RESET_TOKEN_SUCCESS,
  PASSWORD_RECOVERY_FAILURE,
  PASSWORD_RECOVERY_REQUEST,
  PASSWORD_RECOVERY_RESET,
  PASSWORD_RECOVERY_SUCCESS,
} from "../../api/actions";
import { processCustomError } from "../../utils/utils";

export const passwordRecoverySelector = (state) => state.submission;

const initialState = {
  success: false,
  submitting: false,
  validResetToken: false,
  fetchingResetToken: false,
};

// export default function reducer(state = initialState, action = {}) {
export default produce((draft, action = {}) => {
  switch (action.type) {
    case CHECK_RESET_TOKEN_REQUEST:
      draft.fetchingResetToken = true;
      break;

    case CHECK_RESET_TOKEN_SUCCESS:
      draft.fetchingResetToken = false;
      draft.validResetToken = true;
      break;

    case CHECK_RESET_TOKEN_FAILURE:
      draft.fetchingResetToken = false;
      draft.validResetToken = false;
      break;

    case PASSWORD_RECOVERY_REQUEST:
      draft.submitting = true;
      break;

    case PASSWORD_RECOVERY_SUCCESS:
      draft.success = true;
      draft.submitting = false;
      break;

    case PASSWORD_RECOVERY_FAILURE:
      draft.submitting = false;
      draft.success = false;
      draft.errors = get(action, "payload.errors") ||
        processCustomError(action.payload) || {
          error: "Unknown error",
        };
      break;

    case PASSWORD_RECOVERY_RESET:
      Object.assign(draft, initialState);
      break;
  }
}, initialState);
