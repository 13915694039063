/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { publicPath } from "./constants";

export const routes = {
  HOME: {
    path: `${publicPath}home`,
    name: "Home page",
  },
  SUBMIT_CONCERN: {
    path: `${publicPath}submit-report/concern`,
    name: "Submit concern page",
  },
  SUBMIT_QUESTION: {
    path: `${publicPath}submit-report/question`,
    name: "Submit question page",
  },
  SUBMIT_REPORT_SUCCESS: {
    path: `${publicPath}submit-report/success`,
    name: "Submit report success page",
  },
  ISSUE_SUMMARY: {
    path: `${publicPath}issue-summary`,
    name: "Issue Summary page",
  },
  RECOVERY_PASSWORD: {
    path: `${publicPath}resetpassword`,
    name: "Password recovery page",
  },
  SET_PASSWORD: {
    path: `${publicPath}setpassword`,
    name: "Set password page",
  },
  SYS_VERSION: {
    path: `${publicPath}sys/version`,
    name: "Version",
  },
};
