/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import produce from "immer";

import {
  SUBMIT_USER_MESSAGE,
  SUBMIT_USER_MESSAGE_FAILURE,
  SUBMIT_USER_MESSAGE_SUCCESS,
} from "../../api/actions";
import { processCustomError } from "../../utils/utils";
import { COMMUNICATION_CLEAR_ERROR } from "./actions";

export const communicationSelector = (state) => state.communication;

const initialState = {
  submitting: false,
};

export default produce((draft, action = {}) => {
  switch (action.type) {
    case SUBMIT_USER_MESSAGE:
      draft.submitting = true;
      break;
    case SUBMIT_USER_MESSAGE_SUCCESS:
      draft.submitting = false;
      draft.errors = processCustomError(action.payload) || null;
      break;
    case SUBMIT_USER_MESSAGE_FAILURE:
      draft.submitting = false;
      draft.errors = processCustomError(action.payload) || null;
      draft.errorStatus = action.error.status;
      draft.isBlocked = action.error.isBlocked;
      draft.message = action.error.message;
      break;
    case COMMUNICATION_CLEAR_ERROR:
      draft.errorStatus = "";
      draft.isBlocked = false;
      draft.message = "";
      break;
  }
}, initialState);
