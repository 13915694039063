/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

export const COMMUNICATION_FILE_UPLOAD_SUCCESS =
  "COMMUNICATION_FILE_UPLOAD_SUCCESS";
export const COMMUNICATION_FILE_UPLOAD_REMOVE =
  "COMMUNICATION_FILE_UPLOAD_REMOVE";
export const COMMUNICATION_SET_ENTRY = "COMMUNICATION_SET_ENTRY";

export const COMMUNICATION_CLEAR_ERROR = "COMMUNICATION_CLEAR_ERROR";

export function communicationFileUploadSuccess(payload) {
  return {
    type: COMMUNICATION_FILE_UPLOAD_SUCCESS,
    payload,
  };
}

export function communicationFileUploadRemove(payload) {
  return {
    type: COMMUNICATION_FILE_UPLOAD_REMOVE,
    payload,
  };
}

export function setEntry(payload) {
  return {
    type: COMMUNICATION_SET_ENTRY,
    payload,
  };
}

export function communicationClearError() {
  return { type: COMMUNICATION_CLEAR_ERROR };
}
