/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import React, { Component } from "react";
import { defineMessages, FormattedHTMLMessage, injectIntl } from "react-intl";

import ContactUs from "../contact-us/contact-us";

import "./footer.scss";

defineMessages({
  footerTitle: {
    id: "footer.title",
    defaultMessage: "<span class='notranslate'>EPAM EthicsLine</span>",
  },
  footerCopyright: {
    id: "footer.copyright",
    defaultMessage:
      "<span class='notranslate'>EPAM Systems</span>. All rights reserved.",
  },
  securityNoticeTitle: {
    id: "security-notice.title",
    defaultMessage: "Security notice:",
  },
  securityNoticeBody: {
    id: "security-notice.body",
    defaultMessage: `To foster confidentiality, EPAM’s <span class='notranslate'>EthicsLine</span> is located in a
    secure environment with restricted access outside of EPAM’s
    regular infrastructure. Your questions and concerns are received
    directly by our Ethics and Compliance group.`,
  },
});

/*
  NOTE: Contact us section is hidden for now
*/

@injectIntl
class Footer extends Component {
  render() {
    const { intl } = this.props;
    const year = new Date().getFullYear();

    return (
      <div className="footer__container">
        <div className="footer">
          <div className="footer__info">
            <div className="logo">
              <div className="logo__picture">
                <div className="logo__picture-background" />
              </div>
              <div className="title">
                <FormattedHTMLMessage id="footer.title" />
              </div>
            </div>
            <div className="footer__security-notice">
              <h5>{intl.formatMessage({ id: "security-notice.title" })}</h5>
              <p>
                <FormattedHTMLMessage id="security-notice.body" />
              </p>
            </div>
            <div className="copyright">
              © {year} <FormattedHTMLMessage id="footer.copyright" />
            </div>
          </div>
          <div className="footer__contacts">
            <ContactUs />
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
