/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import React, { Component } from "react";

import { Spinner } from "@epam/loveship";

import "./loader.scss";

export class Loader extends Component {
  render() {
    return (
      <div className="loader">
        <Spinner />
      </div>
    );
  }
}
