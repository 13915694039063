/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import produce from "immer";
import get from "lodash/get";

import {
  SUBMIT_REPORT_FAILURE,
  SUBMIT_REPORT_REQUEST,
  SUBMIT_REPORT_SUCCESS,
} from "../../api/actions";
import { processCustomError, processError } from "../../utils/utils";
import { CLEAR_SUCCESS, GET_ISSUE_LOCATIONS_SUCCESS } from "./actions";

export const submissionSelector = (state) => state.submission;

const initialState = {
  success: false,
  login: "",
  infectedAttach: [],
  submitting: false,
  issueLocations: null,
};

export default produce((draft, action = {}) => {
  switch (action.type) {
    case SUBMIT_REPORT_REQUEST:
      draft.submitting = true;
      break;
    case SUBMIT_REPORT_SUCCESS:
      draft.success = true;
      draft.login = action.payload.login;
      draft.infectedAttach = action.payload.infectedAttach;
      draft.submitting = false;
      break;
    case SUBMIT_REPORT_FAILURE:
      draft.submitting = false;
      draft.success = false;
      draft.login = "";
      draft.errors = get(action, "payload.errors") ||
        processCustomError(action.payload) ||
        processError(action.error) || {
          error: "Unknown error",
        };
      break;
    case CLEAR_SUCCESS:
      draft.success = false;
      break;
    case GET_ISSUE_LOCATIONS_SUCCESS:
      draft.issueLocations = action.payload;
      break;
  }
}, initialState);
