/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { fork, put, select, takeEvery } from "redux-saga/effects";

import {
  ISSUE_SUMMARY_FILE_UPLOAD_REMOVE,
  ISSUE_SUMMARY_FILE_UPLOAD_SUCCESS,
  setEntry,
} from "./actions";
import { issueSummarySelector } from "./reducer";

// set issue summary attachments
function* setAttachments(action) {
  const files = action.payload;
  yield put(setEntry({ files }));
}

function* watchSetAttachments() {
  yield takeEvery(ISSUE_SUMMARY_FILE_UPLOAD_SUCCESS, setAttachments);
}

// remove issue summary attachments
function* removeAttachment(action) {
  const {
    entry: { files },
  } = yield select(issueSummarySelector);

  yield put(
    setEntry({
      files: files.filter((file, index) => index !== action.payload),
    })
  );
}

function* watchRemoveAttachment() {
  yield takeEvery(ISSUE_SUMMARY_FILE_UPLOAD_REMOVE, removeAttachment);
}

export function* sagasIssueSummary() {
  yield fork(watchSetAttachments);
  yield fork(watchRemoveAttachment);
}
