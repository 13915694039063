/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import ruDatepicker from "date-fns/locale/ru";
import { createBrowserHistory } from "history";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { createRoot } from "react-dom/client";
import { addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import ru from "react-intl/locale-data/ru";
import { Provider } from "react-intl-redux";
import { Router } from "react-router-dom";
import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { thunk } from "redux-thunk";

import { ErrorHandler } from "@epam/uui";
import { Modals, Snackbar } from "@epam/uui-components";
import {
  DragGhost,
  HistoryAdaptedRouter,
  useUuiServices,
  UuiContext,
} from "@epam/uui-core";

import { configureElasticAPM } from "./elasticAPM";
import { LANGUAGES } from "./i18n/languages";
import App from "./js/features/app/app";
import { rootSaga } from "./js/features/app/effects/root-saga";
import rootReducer from "./js/features/app/reducers/root-reducer";
import { createActionsEnhancer } from "./js/utils/enhancers/actions-enhancer";
import logger from "./js/utils/logger/logger";
import { svc } from "./services";

import "./index.scss";
import "@epam/uui-components/styles.css";
import "@epam/uui/styles.css";
import "@epam/assets/theme/theme_loveship.scss";

const history = createBrowserHistory();
const router = new HistoryAdaptedRouter(history);

registerLocale("ru", ruDatepicker);

configureElasticAPM();

const isProduction = process.env.NODE_ENV === "production";

export let store = null;

const chosenLanguage = localStorage.getItem("lang")
  ? LANGUAGES[localStorage.getItem("lang")]
  : LANGUAGES["en-US"];

const initialState = {
  intl: {
    defaultLocale: "en-US",
    ...chosenLanguage,
  },
};

setDefaultLocale(chosenLanguage.shortCode);

const sagaMiddleware = createSagaMiddleware();

const actionsEnhancer = createActionsEnhancer(sagaMiddleware);

if (isProduction) {
  const productionMiddleware = applyMiddleware(thunk, sagaMiddleware);

  store = createStore(
    rootReducer,
    initialState,
    compose(actionsEnhancer, productionMiddleware)
  );
} else {
  const middleWares = applyMiddleware(thunk, sagaMiddleware, logger);

  let enhancer;

  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    // eslint-disable-line
    enhancer = compose(
      actionsEnhancer,
      middleWares,
      window.__REDUX_DEVTOOLS_EXTENSION__() // eslint-disable-line
    );
  } else {
    enhancer = compose(actionsEnhancer, middleWares);
  }

  store = createStore(rootReducer, initialState, enhancer);
}

// Run the sagas
sagaMiddleware.run(rootSaga);

addLocaleData(en);
addLocaleData(ru);

const UuiEnhancedApp = () => {
  const { services } = useUuiServices({ router });
  Object.assign(svc, services);

  return (
    <Router history={history}>
      <UuiContext.Provider value={services}>
        <ErrorHandler>
          <App history={history} />
          <Snackbar />
          <Modals />
          <DragGhost />
        </ErrorHandler>
      </UuiContext.Provider>
    </Router>
  );
};

function initApp() {
  const root = createRoot(window.document.getElementById("root"));
  root.render(
    <Provider store={store}>
      <UuiEnhancedApp />
    </Provider>
  );
}

initApp();
