/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import produce from "immer";

import { HIDE_NOTIFICATION, SHOW_NOTIFICATION } from "./actions";

const initialState = {
  stack: [],
};

export default produce((draft, action) => {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      draft.stack.push(action.notification);
      break;
    case HIDE_NOTIFICATION: {
      const notToBeHidden = draft.stack.find(
        (not) => not.id === action.notificationId
      );
      const notToBeHiddenIndex = draft.stack.indexOf(notToBeHidden);

      draft.stack.splice(notToBeHiddenIndex, 1);
      break;
    }
  }
}, initialState);
