/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

/**
 * Class for creating the object with id.
 * Can be extended by more specific components.
 */
class ObjectWithId {
  /**
   * Create object with id
   */
  constructor() {
    this.id = this.generateId();
  }

  generateId() {
    return new Date().getTime();
  }
}

/**
 * Class representing notification - boilerplate
 * for more specific cases
 * @extends ObjectWithId
 */
class Notification extends ObjectWithId {
  /**
   * Creates notification
   * @param {string} text
   * @param {number} secondsDelay
   * @param {function[] | function} [actions]
   */
  constructor(text, secondsDelay, actions) {
    super();
    this.text = text;
    this.lifetime = secondsDelay * 1000 || 3000;
    this.id = this.generateId();
    if (actions) {
      this.actions = Array.isArray(actions) ? actions : [actions];
    }
  }
}

/**
 * Class representing an error notification
 * @extends Notification
 */
export class ErrorNotification extends Notification {
  /**
   * Creates error notification
   * @param {string} text
   * @param {number} secondsDelay
   * @param {function[] | function} [actions]
   */
  constructor(text, secondsDelay, actions) {
    super(text, secondsDelay, actions);
    this.type = "error";
  }
}

/**
 * Class representing a warning notification
 * @extends Notification
 */
export class WarningNotification extends Notification {
  /**
   * Creates warning notification
   * @param {string} text
   * @param {number} secondsDelay
   * @param {function[] | function} [actions]
   */
  constructor(text, secondsDelay, actions) {
    super(text, secondsDelay, actions);
    this.type = "warning";
  }
}

/**
 * Class representing a success notification
 * @extends Notification
 */
export class SuccessNotification extends Notification {
  /**
   * Creates success notification
   * @param {string} text
   * @param {number} secondsDelay
   * @param {function[] | function} [actions]
   */
  constructor(text, secondsDelay, actions) {
    super(text, secondsDelay, actions);
    this.type = "success";
  }
}

/** Class representing a notification action
 * @extends ObjectWithId
 */
export class NotificationAction extends ObjectWithId {
  /**
   * Create a notification action
   * @param {function} action
   * @param {string} label
   */
  constructor(action, label) {
    super();
    this.action = action;
    this.label = label;
  }
}
