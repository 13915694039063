/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { spawn } from "redux-saga/effects";

import { sagasI18n } from "../../../../i18n/effects";
import { sagasAccessSubmission } from "../../../business/access-submission/effects";
import { sagasCommunication } from "../../../business/communication/effects";
import { sagasNotifications } from "../../../business/notifications/effects";
import { sagasFileUpload } from "../../../components/controls/file-upload/effects";
import { sagasIssueSummary } from "../../issue-summary/effects";
import { sagasSubmission } from "../../submission/effects";
import { sagasGeneral } from "./effects";

export function* rootSaga() {
  yield spawn(sagasGeneral);
  yield spawn(sagasSubmission);
  yield spawn(sagasIssueSummary);
  yield spawn(sagasFileUpload);
  yield spawn(sagasCommunication);
  yield spawn(sagasAccessSubmission);
  yield spawn(sagasNotifications);
  yield spawn(sagasI18n);
}
