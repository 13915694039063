const sortByName = (item1, item2) => {
  if (item1.name > item2.name) return 1;
  if (item1.name < item2.name) return -1;
  return 0;
};

export const otherItem = { name: "Other", id: "Other" };

export const mapIssueLocationsResponse = (response) => {
  const mappedCountries = [];
  const mappedCities = {};

  response.countries.sort(sortByName).forEach(({ name, cities }) => {
    mappedCountries.push({ name, id: name });
    mappedCities[name] = [
      ...cities.sort(sortByName).map(({ name }) => ({
        name,
        id: name,
      })),
      otherItem,
    ];
  });

  return { countries: [...mappedCountries, otherItem], cities: mappedCities };
};
