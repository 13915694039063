/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import messagesEnUS from "./locales/en-US.json";
// import messagesRuRU from "./locales/ru-RU.json";

export const LANGUAGES = {
  /* "ru-RU": {
    locale: "ru-RU",
    messages: messagesRuRU,
    name: "Русский",
    shortCode: "ru"
  }, */
  "en-US": {
    locale: "en-US",
    messages: messagesEnUS,
    name: "English",
    shortCode: "en",
  },
};
