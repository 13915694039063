/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { autobind } from "core-decorators";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { hideNotification, showNotification } from "./actions";
import NotificationsHub from "./notifications-hub";
import {
  ErrorNotification,
  NotificationAction,
  WarningNotification,
} from "./utils";

import "./notifications.scss";

const mapStateToProps = (state) => ({
  notifications: state.notifications.stack,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showNotification,
      hideNotification,
    },
    dispatch
  );

@connect(mapStateToProps, mapDispatchToProps)
class Notifications extends Component {
  @autobind
  testNotification() {
    const action = new NotificationAction(() => {
      return "some action";
    }, "Save and relogin");

    Math.random() > 0.5
      ? this.props.showNotification({
          notification: new ErrorNotification(
            "Sorry, we lost connection with server. Your changes will not be saved.",
            30,
            action
          ),
        })
      : this.props.showNotification({
          notification: new WarningNotification(
            "Your session will expire in 5 minutes. Please save changes and relogin.",
            3,
            action
          ),
        });
  }

  @autobind
  handleNotificationHide({ id }) {
    this.props.hideNotification(id);
  }

  render() {
    const { notifications, test } = this.props;

    if (!test) {
      return (
        <div className="notifications__container">
          <NotificationsHub
            items={notifications}
            onNotificationHide={this.handleNotificationHide}
          />
        </div>
      );
    }

    // ATTENTION only in development purpose
    return (
      <div style={{ display: "flex", flexDirection: "row-reverse" }}>
        <div className="notifications__container">
          <NotificationsHub
            items={notifications}
            onNotificationHide={this.handleNotificationHide}
          />
        </div>
        <button type="button" onClick={this.testNotification}>
          TEST NOTIFICATION
        </button>
      </div>
    );
  }
}

export default Notifications;
