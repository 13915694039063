/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import classNames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { defineMessages, injectIntl } from "react-intl";

import { LOCALE } from "../../components/general/contact-us/constants";

import "./phone-number.scss";

defineMessages({
  russianSpeakingLine: {
    id: "footer.language.russian",
    defaultMessage: "Russian Speaking Line",
  },
  englishSpeakingLine: {
    id: "footer.language.english",
    defaultMessage: "English Speaking Line",
  },
  availableTimeEN: {
    id: "footer.available-time.en",
    defaultMessage: "Available 24/7",
  },
  availableTimeRU: {
    id: "footer.available-time.ru",
    defaultMessage: "Available 8am - 7pm Minsk time",
  },
});

@injectIntl
class PhoneNumber extends Component {
  static propTypes = {
    value: PropTypes.string,
    locale: PropTypes.string,
  };

  render() {
    const { value, locale, intl } = this.props;

    const flagClassName = classNames("phone-number__flag", {
      "phone-number__flag--us": locale === LOCALE.us,
      "phone-number__flag--ru": locale === LOCALE.ru,
    });

    const speakingLineText = intl.formatMessage({
      id:
        locale === LOCALE.us
          ? "footer.language.english"
          : "footer.language.russian",
    });
    const availableTimeText = intl.formatMessage({
      id:
        locale === LOCALE.us
          ? "footer.available-time.en"
          : "footer.available-time.ru",
    });

    return (
      <div className="phone-number">
        <div className={flagClassName} />
        <div className="phone-number__content">
          <div className="phone-number__content-text">{speakingLineText}</div>
          <div
            className="phone-number__content-value"
            x-ms-format-detection="none"
          >
            {value}
          </div>
          <div className="phone-number__content-text">{availableTimeText}</div>
        </div>
      </div>
    );
  }
}

PhoneNumber.defaultProps = {
  value: "",
  locale: "us",
};

export default PhoneNumber;
