/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

export const SUBMISSION_FILE_UPLOAD_SUCCESS = "SUBMISSION_FILE_UPLOAD_SUCCESS";
export const SUBMISSION_FILE_UPLOAD_REMOVE = "SUBMISSION_FILE_UPLOAD_REMOVE";
export const CLEAR_SUCCESS = "CLEAR_SUCCESS";

export const GET_ISSUE_LOCATIONS_REQUEST = "GET_ISSUE_LOCATIONS_REQUEST";
export const GET_ISSUE_LOCATIONS_SUCCESS = "GET_ISSUE_LOCATIONS_SUCCESS";

export function getIssueLocationsRequest() {
  return {
    type: GET_ISSUE_LOCATIONS_REQUEST,
  };
}

export function getIssueLocationsSuccess(payload) {
  return {
    type: GET_ISSUE_LOCATIONS_SUCCESS,
    payload,
  };
}

export function submissionFileUploadSuccess(payload) {
  return {
    type: SUBMISSION_FILE_UPLOAD_SUCCESS,
    payload,
  };
}

export function submissionFileUploadRemove(payload) {
  return {
    type: SUBMISSION_FILE_UPLOAD_REMOVE,
    payload,
  };
}

export function clearSuccess() {
  return {
    type: CLEAR_SUCCESS,
  };
}
