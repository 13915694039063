/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

export const SUBMIT_REPORT_ENDPOINT = "/api/Reports";
export const AUTH = "/api/auth";

export const REPORTS_USERS = "/api/Reports/users";
export const USER_MESSAGE_ENDPOINT = "/api/messages/users";

export const DOWNLOAD_FILE_ENDPOINT = "/api/files";

export const ISSUE_TYPES_ENPOINT = "/api/IssueTypes";
export const LOCATIONS_ENDPOINT = "/api/Locations/Countries";
export const APPROVED_FILEFORMATS_ENPOINT = "/api/Files/approvedformats";
export const SEND_RESET_NOTIFICATION = "/api/auth/sendresetnotification";
export const GET_CAPTCHA = "/api/auth/captcha";
export const CHECK_RESET_TOKEN = "/api/auth/checkresettoken";
export const RESET_PASSWORD = "/api/auth/resetpassword";
export const SET_PASSWORD = "/api/auth/setpassword";
export const SYS_VERSION_ENPOINT = "/api/sys/version";
export const IS_BLOCKED_REPORT = "/api/Settings/isreportblocked";
export const IS_ADDRESS_ACCESS_DINIED =
  "/api/ReportAddressAuthorization/isAddressAccessDenied";
