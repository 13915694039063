/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { createLogger } from "redux-logger";

const logger = createLogger({
  stateTransformer: (state) => {
    const newState = {};

    Object.keys(state).forEach((key) => {
      newState[key] = state[key];
    });

    return newState;
  },
  collapsed: true,
});

export default logger;
