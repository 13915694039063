import { init as initApm } from "@elastic/apm-rum";

const ENVS = {
  dev: "development",
  qa: "qa",
  preprod: "preprod",
  prod: "production",
};

const ENV_URLS = {
  [ENVS.qa]: "ethicsline.lab.epam.com",
  [ENVS.preprod]: "ethicsline.epm-iass.projects.epam.com",
  [ENVS.prod]: "ethics.epam.com",
};

const elasticConfig = {
  project: "epm-eil",
  serviceType: "ui",
  serviceName: "ethics-ui",
};

const getEnvironment = () => {
  const { origin } = window.location;

  if (origin.includes(ENV_URLS[ENVS.qa])) {
    return ENVS.qa;
  } else if (origin.includes(ENV_URLS[ENVS.preprod])) {
    return ENVS.preprod;
  } else if (origin.includes(ENV_URLS[ENVS.prod])) {
    return ENVS.prod;
  }

  return process.env.NODE_ENV;
};

export let elasticAPM = null;

export function configureElasticAPM() {
  elasticAPM = initApm({
    serverUrl: process.env.REACT_APP_ELASTIC_APM_URL,
    serverUrlPrefix: "/rum-events",
    environment: getEnvironment(),
    serviceName: elasticConfig.serviceName,
    serviceVersion: process.env.REACT_APP_VERSION,
    breakdownMetrics: true,
    transactionSampleRate: 0.2,
    disableInstrumentations: ["fetch", "xmlhttprequest"],
  });

  elasticAPM.addLabels({
    project: elasticConfig.project,
    service_type: elasticConfig.serviceType,
  });
  elasticAPM.setInitialPageLoadName("EthicsLine");
}
