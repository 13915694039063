/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

export const ISSUE_SUMMARY_INIT = "ISSUE_SUMMARY_INIT";
export const ISSUE_SUMMARY_SET_ENTRY = "ISSUE_SUMMARY_SET_ENTRY";
export const ISSUE_SUMMARY_FILE_UPLOAD_SUCCESS =
  "ISSUE_SUMMARY_FILE_UPLOAD_SUCCESS";
export const ISSUE_SUMMARY_FILE_UPLOAD_REMOVE =
  "ISSUE_SUMMARY_FILE_UPLOAD_REMOVE";

export function setEntry(payload) {
  return {
    type: ISSUE_SUMMARY_SET_ENTRY,
    payload,
  };
}

export function issueSummaryInit() {
  return {
    type: ISSUE_SUMMARY_INIT,
  };
}

export function issueSummaryFileUploadSuccess(payload) {
  return {
    type: ISSUE_SUMMARY_FILE_UPLOAD_SUCCESS,
    payload,
  };
}

export function issueSummaryFileUploadRemove(payload) {
  return {
    type: ISSUE_SUMMARY_FILE_UPLOAD_REMOVE,
    payload,
  };
}
