/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import produce from "immer";

import {
  GET_CAPTCHA_FAILURE,
  GET_CAPTCHA_REQUEST,
  GET_CAPTCHA_SUCCESS,
  LOGIN_SUCCESS,
} from "../../../api/actions";
import { APP_SERVER_ERROR, LOGOUT } from "../actions/app";

const getAuthData = () =>
  JSON.parse(sessionStorage.getItem("EL.AUTH")) || {
    token: "",
    user: {},
  };

const initialState = {
  locale: "en-US",
  serverError: false,
  auth: {
    ...getAuthData(),
    sessionTimeout: sessionStorage.getItem("EL.SESSION_TIMEOUT") || null,
    captcha: {},
  },
};

export default produce((draft, action = {}) => {
  switch (action.type) {
    case APP_SERVER_ERROR:
      draft.serverError = true;
      break;
    case LOGIN_SUCCESS: {
      const {
        payload: { token, sessionTimeout },
      } = action;

      draft.auth.access_token = token;
      draft.auth.sessionTimeout = sessionTimeout;
      draft.auth.user = {
        role: "user",
      };

      break;
    }
    case LOGOUT: {
      draft.auth = {
        access_token: null,
        user: {},
        sessionTimeout: null,
        captcha: {},
      };
      break;
    }

    case GET_CAPTCHA_REQUEST:
      draft.auth.captcha = {
        isLoading: true,
      };
      break;

    case GET_CAPTCHA_SUCCESS:
      draft.auth.captcha = {
        isLoading: false,
        type: action.payload.type,
        siteKey: action.payload.siteKey,
      };
      break;

    case GET_CAPTCHA_FAILURE:
      draft.auth.captcha = {
        isLoading: false,
        error: action.payload.error,
      };
      break;
  }
}, initialState);
