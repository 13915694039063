/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { fork, takeEvery } from "@redux-saga/core/effects";

import { LOGIN_SUCCESS } from "../../api/actions";
import { routes } from "../../features/app/routes";

function login(action) {
  const {
    meta: { history },
  } = action;
  const {
    payload: { token, sessionTimeout },
  } = action;
  const auth = JSON.stringify({
    access_token: token,
    user: {
      role: "user",
    },
  });

  history.push(routes.ISSUE_SUMMARY.path);
  sessionStorage.setItem("EL.AUTH", auth);
  sessionStorage.setItem("EL.SESSION_TIMEOUT", sessionTimeout);
}

function* watchLogin() {
  yield takeEvery(LOGIN_SUCCESS, login);
}

export function* sagasAccessSubmission() {
  yield fork(watchLogin);
}
