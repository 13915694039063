/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

export const FILE_UPLOAD_INIT = "FILE_UPLOAD_INIT";
export const FILE_UPLOAD_ABORT = "FILE_UPLOAD_ABORT";
export const FILE_UPLOAD_FAILURE = "FILE_UPLOAD_FAILURE";
export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS";
export const FILE_UPLOAD_REMOVE = "FILE_UPLOAD_REMOVE";
export const FILE_UPLOAD_START = "FILE_UPLOAD_START";

export const FILE_DOWNLOAD_SUCCEEDED = "FILE_DOWNLOAD_SUCCEEDED";
export const FILE_DOWNLOAD_FAILED = "FILE_DOWNLOAD_FAILED";

export function fileUploadInit() {
  return {
    type: FILE_UPLOAD_INIT,
  };
}

export function fileUploadStart(payload) {
  return {
    type: FILE_UPLOAD_START,
    payload,
  };
}

export function fileUploadAbort(payload) {
  return {
    type: FILE_UPLOAD_ABORT,
    payload,
  };
}

export function fileUploadFailure(payload) {
  return {
    type: FILE_UPLOAD_FAILURE,
    payload,
  };
}

export function fileUploadSuccess() {
  return {
    type: FILE_UPLOAD_SUCCESS,
  };
}

export function fileUploadRemove(payload) {
  return {
    type: FILE_UPLOAD_REMOVE,
    payload,
  };
}

export function fileDownladSucceded(payload) {
  return {
    type: FILE_DOWNLOAD_SUCCEEDED,
    payload,
  };
}

export function fileDownladFailed(payload) {
  return {
    type: FILE_DOWNLOAD_FAILED,
    payload,
  };
}
