/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { delay, fork, put, takeEvery } from "redux-saga/effects";

import { hideNotification, SHOW_NOTIFICATION } from "./actions";

function* showNotification(action) {
  const {
    notification: { id, lifetime },
  } = action;

  yield delay(lifetime);
  yield put(hideNotification(id));
}

export function* watchShowNotification() {
  yield takeEvery(SHOW_NOTIFICATION, showNotification);
}

export function* sagasNotifications() {
  yield fork(watchShowNotification);
}
