/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import produce from "immer";

import { SET_LOCALE } from "./actions";
import { LANGUAGES } from "./languages";

const chosenLanguage = localStorage.getItem("lang")
  ? LANGUAGES[localStorage.getItem("lang")]
  : LANGUAGES["en-US"];

const initialState = {
  defaultLocale: "en-US",
  ...chosenLanguage,
};

export default produce((draft, action) => {
  switch (action.type) {
    case SET_LOCALE:
      draft.locale = LANGUAGES[action.payload].locale;
      draft.messages = LANGUAGES[action.payload].messages;
      draft.name = LANGUAGES[action.payload].name;
      draft.shortCode = LANGUAGES[action.payload].shortCode;
      break;
  }
}, initialState);
