/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import DOMPurify from "dompurify";
import isArray from "lodash/isArray";
import isUndefined from "lodash/isUndefined";
import omitBy from "lodash/omitBy";

import { CUSTOM_ERRORS_CODE } from "../features/submission/constants";

export function omitUndefinedProps(props) {
  return omitBy(
    props,
    (prop) =>
      isUndefined(prop) ||
      prop === "" ||
      prop === null ||
      (isArray(prop) && !prop.length)
  );
}

export function formatDate(date) {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}

const MILLISECONDS_IN_MINUTE = 60000;
const options = {
  day: "numeric",
  month: "long",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
};

export function formatDateAndTime(time, { isLocal }) {
  if (time) {
    let date = new Date(time);

    if (isLocal) {
      const localDate = new Date(
        date.getTime() - date.getTimezoneOffset() * MILLISECONDS_IN_MINUTE
      );
      date = localDate;
    }

    const res = new Intl.DateTimeFormat("en-GB", options).format(date);

    return shouldRemoveExactTime(time)
      ? removeExactTime(res)
      : formatHours(res);
  }
  return null;
}

// auto time when user didn't set exact time (see submitReport function)
const shouldRemoveExactTime = (time) => time.endsWith("00:00:00");

const removeExactTime = (time) => time.replace(/ at \d\d:\d\d/, "");
const formatHours = (time) => time.replace(" at ", ", ");

export function formData(values) {
  const form = new FormData();
  for (const key in values) {
    if (key === "files") {
      for (let i = 0; i < values[key].length; i++) {
        form.append("files", values[key][i], values[key][i].name);
      }
    } else {
      form.append(key, values[key]);
    }
  }
  return form;
}

export function formatMillesecondsToTime(time) {
  let minutes, seconds;
  if (time) {
    minutes = Math.floor((time / 1000 / 60) % 60);
    seconds = Math.floor((time / 1000) % 60);

    // then we should format the seconds, to add zeroes ahead
    seconds = `0${seconds}`.slice(-2);
  }
  return `${minutes}:${seconds}`;
}

export function getRemainingTime(endTime) {
  let timeRemained;

  if (endTime) {
    timeRemained = new Date(+endTime) - Date.now();
  }
  return timeRemained;
}

export function getFormattedRemainingTime(endTime) {
  return formatMillesecondsToTime(getRemainingTime(endTime));
}

export function addMinutesToTime(time, minutes) {
  return new Date(time.getTime() + minutes * 60000);
}

export function addMinutesToCurrentTime(minutes) {
  const currentTime = new Date();
  return addMinutesToTime(currentTime, minutes);
}

export function isValidURL(string) {
  const res = string.match(
    /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/gim
  );
  return res !== null && res[0];
}

export function cutOffLongFileName(file) {
  const poindIdx = file.lastIndexOf(".");
  const fileExtension = poindIdx !== -1 ? file.substring(poindIdx) : "";
  const shortFileName = file.substring(0, 15);
  return shortFileName + "..." + fileExtension;
}

export function xssProcessing(val) {
  if (val === undefined || val == null) {
    return val;
  }
  const valSanitized = DOMPurify.sanitize(val, { ALLOWED_TAGS: [] });
  if (valSanitized !== val) {
    return "!!!DANGEROUS CONTENT DETECTED AND HAS BEEN REMOVED!!!";
  }

  return val;
}

export function processCustomError(payload) {
  if (payload === CUSTOM_ERRORS_CODE.ADDRESS_ACCESS) {
    return { error: "app.errors.ip-address-block-report" };
  }

  if (payload && payload.ErrorCode) {
    let res = payload.Description && {
      error: payload.Description,
    };
    switch (payload.ErrorCode) {
      case CUSTOM_ERRORS_CODE.XSS_DETECTED:
        res = {
          error: "app.errors.xss-detected",
        };
        break;
    }

    return res;
  }
  return null;
}

export function processError(error) {
  if (error.status === 403) {
    const res = {
      error: "app.errors.block-report",
    };
    return res;
  }
  return null;
}
