/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

export const SHOW_NOTIFICATION = "[NOTIFICATION] SHOW NOTIFICATION";
export const HIDE_NOTIFICATION = "[NOTIFICATION] HIDE NOTIFICATION";

export const showNotification = ({ notification }) => ({
  type: SHOW_NOTIFICATION,
  notification,
});

export const hideNotification = (notificationId) => ({
  type: HIDE_NOTIFICATION,
  notificationId,
});
