/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import produce from "immer";

import { FILE_UPLOAD_START, FILE_UPLOAD_SUCCESS } from "./actions";

const initialState = {
  isLoading: false,
};

export default produce((draft, action = {}) => {
  switch (action.type) {
    case FILE_UPLOAD_START:
      draft.isLoading = true;
      break;
    case FILE_UPLOAD_SUCCESS:
      draft.isLoading = false;
      break;
  }
}, initialState);
