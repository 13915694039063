/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { change, formValueSelector } from "redux-form";
import { call, fork, put, select, takeEvery } from "redux-saga/effects";

import { LOCATIONS_ENDPOINT } from "../../api/endpoints";
import { fetchData } from "../../api/fetch-wrapper";
import { showNotification } from "../../business/notifications/actions";
import notificationMessages from "../../business/notifications/messages";
import {
  ErrorNotification,
  NotificationAction,
} from "../../business/notifications/utils";
import {
  GET_ISSUE_LOCATIONS_REQUEST,
  getIssueLocationsSuccess,
  SUBMISSION_FILE_UPLOAD_REMOVE,
  SUBMISSION_FILE_UPLOAD_SUCCESS,
} from "./actions";
import { mapIssueLocationsResponse } from "./utils";

const mockResponse = JSON.parse(
  process.env.REACT_APP_LOCATIONS_MOCK_RESPONSE || "{}"
);

// submission set attachments
function* setAttachments(action) {
  const oldFiles = yield select((state) =>
    formValueSelector("submission")(state, "files")
  );
  const { files: newFiles } = action.payload;
  yield put(change("submission", "files", oldFiles.concat(newFiles)));
}

function* watchSetAttachments() {
  yield takeEvery(SUBMISSION_FILE_UPLOAD_SUCCESS, setAttachments);
}

// submission remove attachment
function* removeAttachment(action) {
  const files = yield select((state) =>
    formValueSelector("submission")(state, "files")
  );
  yield put(
    change(
      "submission",
      "files",
      files.filter((file, index) => index !== action.payload)
    )
  );
}

function* watchRemoveAttachment() {
  yield takeEvery(SUBMISSION_FILE_UPLOAD_REMOVE, removeAttachment);
}

function* getIssueLocations() {
  try {
    const result = yield call(fetchData, {
      url: LOCATIONS_ENDPOINT,
      locale: "en-US",
    });

    const mappedLocations = mapIssueLocationsResponse(
      result.response || mockResponse
    );
    yield put(getIssueLocationsSuccess(mappedLocations));

    if (result.error) {
      throw new Error(result.error.message);
    }
  } catch (e) {
    console.error("Error in getIssueLocations: ", e);
    const action = new NotificationAction(
      () => window.location.reload(),
      notificationMessages.reloadPageButton.defaultMessage
    );

    const notification = new ErrorNotification(
      notificationMessages.errorNotificationText.defaultMessage,
      5,
      action
    );

    yield put(showNotification({ notification }));
  }
}

function* watchGetIssueLocations() {
  yield takeEvery(GET_ISSUE_LOCATIONS_REQUEST, getIssueLocations);
}

export function* sagasSubmission() {
  yield fork(watchSetAttachments);
  yield fork(watchRemoveAttachment);
  yield fork(watchGetIssueLocations);
}
