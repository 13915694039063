/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import each from "lodash/each";
import has from "lodash/has";
import isEqual from "lodash/isEqual";
import uniqWith from "lodash/uniqWith";
import { put } from "redux-saga/effects";

import devLog from "../logger/dev-log";

export function* runActions(actions) {
  for (const action of actions) {
    yield put(action);
  }
}

export const createActionsEnhancer =
  (sagaMiddleware) => (next) => (reducer, initialState, enhancer) => {
    let actions = [];

    const actionsReducer = (reducer) => (state, action) => {
      const result = reducer(state, action);
      each(result, (subState) => {
        if (has(subState, "actions") && subState.actions.length) {
          actions.push(...subState.actions);
          delete subState.actions;
        }
      });
      if (actions.length) {
        const actionsToRun = uniqWith(actions, isEqual);
        actions = [];
        setTimeout(() => {
          devLog("run actions", action.type, actionsToRun);
          sagaMiddleware.run(runActions, actionsToRun);
        }, 0);
      }
      return result;
    };

    const store = next(actionsReducer(reducer), initialState, enhancer);

    const replaceReducer = (reducer) => {
      return store.replaceReducer(actionsReducer(reducer));
    };

    return {
      ...store,
      replaceReducer,
    };
  };
