/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import React from "react";
import { animated, useTransition } from "react-spring";

import Notification from "../../components/general/notification/notification";

const ANIMATION_CONFIG = {
  tension: 125,
  friction: 30,
  precision: 0.2,
};

const AnimatedNotification = ({ style, item, onClose }) => {
  return (
    <animated.div className="notifications__children" style={style}>
      <Notification
        content={item.text}
        type={item.type}
        onClose={onClose}
        actions={item.actions}
      />
    </animated.div>
  );
};

const NotificationsHub = ({ items, onNotificationHide }) => {
  const transitions = useTransition(items, (item) => item?.id, {
    from: { opacity: 0.8, maxHeight: 0, marginTop: 0 },
    enter: () => async (next) => {
      const nextValue = await next({
        opacity: 1,
        maxHeight: 1000,
        marginTop: 20,
      });
      return nextValue;
    },
    leave: () => async (next) => {
      await next({ opacity: 0, maxHeight: 0, marginTop: 0 });
    },
    config: ANIMATION_CONFIG,
  });

  return (
    <animated.div>
      {transitions.map(({ key, item, props }) => {
        return (
          item && (
            <AnimatedNotification
              key={key}
              style={props}
              item={item}
              onClose={() => onNotificationHide(item)}
            />
          )
        );
      })}
    </animated.div>
  );
};

export default NotificationsHub;
