/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import produce from "immer";

import {
  FILE_DOWNLOAD_REQUEST,
  ISSUE_SUMMARY_FAILURE,
  ISSUE_SUMMARY_REQUEST,
  ISSUE_SUMMARY_SUCCESS,
  SUBMIT_USER_MESSAGE_SUCCESS,
} from "../../api/actions";
import {
  FILE_DOWNLOAD_FAILED,
  FILE_DOWNLOAD_SUCCEEDED,
} from "../../components/controls/file-upload/actions";

export const issueSummarySelector = (state) => state.issueSummary;

const initialState = {
  entry: {
    user: {},
  },
  fetchingData: false,
};

export default produce((draft, action = {}) => {
  switch (action.type) {
    case ISSUE_SUMMARY_REQUEST:
      draft.fetchingData = true;
      break;
    case ISSUE_SUMMARY_SUCCESS:
      draft.fetchingData = false;
      draft.entry = action.payload;
      break;
    case ISSUE_SUMMARY_FAILURE:
      draft.fetchingData = false;
      break;
    case SUBMIT_USER_MESSAGE_SUCCESS:
      draft.entry.messages = action.payload;
      break;
    case FILE_DOWNLOAD_REQUEST:
    case FILE_DOWNLOAD_FAILED: {
      const {
        payload: {
          options: {
            file: { id },
          },
        },
      } = action;
      const { entry } = draft;
      const { files, messages } = entry;

      draft.entry.files = files.map((file) =>
        file.id === id ? { ...file, loading: true } : file
      );
      draft.entry.messages = messages.map((message) => {
        const newAttachments = message.files.map((attachment) => {
          return attachment.id === id
            ? { ...attachment, loading: true }
            : attachment;
        });
        return { ...message, files: newAttachments };
      });
      break;
    }
    case FILE_DOWNLOAD_SUCCEEDED: {
      const id = action.payload;
      const { entry } = draft;
      const { files, messages } = entry;

      draft.entry.files = files.map((file) =>
        file.id === id ? { ...file, loading: false } : file
      );
      draft.entry.messages = messages.map((message) => {
        const newAttachments = message.files.map((attachment) => {
          return attachment.id === id
            ? { ...attachment, loading: false }
            : attachment;
        });
        return { ...message, files: newAttachments };
      });
      break;
    }
  }
}, initialState);
