/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { setDefaultLocale } from "react-datepicker";
import { fork, takeEvery } from "redux-saga/effects";

import { SET_LOCALE } from "./actions";
import { LANGUAGES } from "./languages";

function* changeLocale(action) {
  setDefaultLocale(LANGUAGES[action.payload].shortCode);
  localStorage.setItem("lang", action.payload);
}

function* watchChangeLocale() {
  yield takeEvery(SET_LOCALE, changeLocale);
}

export function* sagasI18n() {
  yield fork(watchChangeLocale);
}
