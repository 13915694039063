/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { combineReducers } from "redux";
import { reducer as form } from "redux-form";

import intl from "../../../../i18n/reducer";
import accessSubmission from "../../../business/access-submission/reducer";
import communication from "../../../business/communication/reducer";
import notifications from "../../../business/notifications/reducer";
import passwordRecoveryPopup from "../../../business/password-recovery-popup/reducer";
import fileUpload from "../../../components/controls/file-upload/reducer";
import issueSummary from "../../issue-summary/reducer";
import passwordRecovery from "../../password-recovery/reducer";
import submission from "../../submission/reducer";
import app from "./app";

const reducers = combineReducers({
  intl,
  app,
  accessSubmission,
  communication,
  submission,
  issueSummary,
  form,
  fileUpload,
  notifications,
  passwordRecovery,
  passwordRecoveryPopup,
});

export default reducers;
