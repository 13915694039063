import React from "react";

import "./server-error.scss";

const SUPPORT_URL =
  "https://support.epam.com/ess?id=sc_cat_item_guide&table=sc_cat_item&sys_id=9276df439767d55c386e3a871153af8a";

export const ServerError = () => (
  <div className="error-page">
    <div className="error-page__section">
      <div className="error-page__section-title">Server Error</div>
      <h3>
        The server encountered an error and could not complete your request.
      </h3>
      <p>
        Please reload the page and try again or contact
        <a href={SUPPORT_URL}>support.epam.com</a>.
      </p>
    </div>
  </div>
);
